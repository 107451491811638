import React from 'react'
import ReactPlayer from 'react-player'
import camelcase from 'camelcase'

import styles from './flexibleContent.module.scss'

export const FlexibleContent = ({ block }) => {
  console.log('flexible-content', block)
  return (
    <section className={styles.section}>
      {block.title && <h1>{block.title}</h1>}
      {block.tabs.filter(tab => !tab.settings.disabled).map((tab, i) => (
        <div key={i}>
          {tab.label && <h2>{tab.label}</h2>}
          {tab.content.filter(content => !content.settings.disabled).map((content, i) => (
            <div key={i}>
              {content.template === 'rich-text' && (
                content.content && <div dangerouslySetInnerHTML={{ __html: content.content }} />
              )}
              {content.template === 'video' && (
                <>
                  <ReactPlayer url={content.videoUrl} />
                  <p>{content.label}</p>
                </>
              )}
              {content.template === 'button' && (
                <button className={styles[camelcase(content.style)]}>{content.buttonText}</button>
              )}
            </div>
          ))}
        </div>
      ))}
    </section>
  )
}
