import React from 'react'
import { Link } from 'gatsby'

import Image from '../image'
import { link } from '../../utilities'

import styles from './testimonial.module.scss'

export const Testimonial = ({ block }) => {
  return (
    <section className={styles.section}>
      <section className={styles.left}>
        {block.quote && <p>{block.quote}</p>}
        {block.author && <p>{block.author}</p>}
        {block.buttonLabel && <button>{block.buttonLabel}</button>}
      </section>
      <section className={styles.right}>
        {block.images.filter(image => !image.settings.disabled).map((image, i) => {
          return (
            <Image key={i} className={styles.image} src={image.image} alt={image.alt} />
          )
        })}
      </section>
    </section>
  )
}
