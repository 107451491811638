import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { ContactForm, Faqs, FlexibleContent, Hero, ImageAndText, ImageBar, Map, Posts, Slider, StackedImagesAndText, TabbedImages, TabbedText, Testimonial, Text } from '../components/page'

import styles from './page.module.scss'

const Page = ({ data: { pagesJson } }) => {
  // console.log(pagesJson)
  return (
    <Layout title={pagesJson.title}>
      <main id='app' className={styles.main + `${pagesJson.settings.customClassName ? ` ${styles[pagesJson.settings.customClassName]}` : ''}`}>
        {pagesJson.rows.filter(row => !row.settings.disabled).map((row, i) => (
          <div key={i} className={styles.row + `${row.settings.customClassName ? ` ${styles[row.settings.customClassName]}` : ''}`}>
            <h1>Row</h1>
            {row.columns.filter(column => !column.settings.disabled).map((column, i) => (
              <div key={i} className={styles.column + `${column.settings.customClassName ? ` ${styles[column.settings.customClassName]}` : ''}`}>
                <h2>Column</h2>
                {column.blocks.filter(block => !block.settings.disabled).map((block, i) => {
                  console.log(block.template, block)
                  if (block.template === 'contact-form') return <ContactForm key={i} block={block} />
                  if (block.template === 'filtered-images-slider') return <p>{block.template}</p>
                  if (block.template === 'flexible-content') return <FlexibleContent key={i} block={block} />
                  if (block.template === 'gallery') return <p key={i}>{block.template}</p>
                  if (block.template === 'hero') return <p key={i}>{block.template}</p>
                  if (block.template === 'image-bar') return <p key={i}>{block.template}</p>
                  if (block.template === 'images') return <p key={i}>{block.template}</p>
                  if (block.template === 'knowledge-base') return <p key={i}>{block.template}</p>
                  if (block.template === 'map') return <p key={i}>{block.template}</p>
                  if (block.template === 'posts') return <p key={i}>{block.template}</p>
                  if (block.template === 'testimonial') return <Testimonial key={i} block={block} />
                  return null
                })}
              </div>
            ))}
          </div>
        ))}
      </main>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query ($title: String!) {
    pagesJson(title: {eq: $title }) {
      title
      type
      rows {
        settings {
          disabled
          customClassName
        }
        columns {
          settings {
            customClassName
            disabled
          }
          blocks {
            title
            template
            tabs {
              label
              content {
                template
                content
                buttonText
                color
                label
                style
                videoUrl
                settings {
                  customClassName
                  disabled
                }
              }
              settings {
                customClassName
                disabled
              }
            }
            settings {
              customClassName
              disabled
            }
            subtitle
            pageclipLink
            nameLabel
            methodLabel
            buttonLabel
            termsAndConditionsLabel
            author
            buttonLabel
            quote
            images {
              alt
              description
              image
              title
              settings {
                customClassName
                disabled
              }
            }
          }
        }
      }
      settings {
        customClassName
        disabled
      }
    }
  }
`
